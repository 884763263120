import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { useParams, useSearchParams } from "react-router-dom";

import "./index.css";

const RegionalsElectionResultsComponent = () => {
  const [winnersData, setWinnersData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { regional } = useParams();
  const baseUrl = searchParams.get("base-url");
  const subUrl = searchParams.get("sub-cat");
  console.log(baseUrl, subUrl, searchParams);

  const redirectUrl = {
    up: "https://www.uptak.in",
    mp: "https://www.mptak.in/elections",
    ch: "https://www.chhattisgarhtak.in/elections",
    rj: "https://www.rajasthantak.com/elections",
    gj: "https://www.gujarattak.in/elections",
    mt: "https://mumbaitak.in",
  };
  const stateId = {
    up: "S24",
    mp: "S12",
    ch: "S26",
    rj: "S20",
    gj: "S06",
    mt: "S13"
  }
  const apiStateName = {
    up: "uttar-pradesh",
    mp: "madhya-pradesh",
    cg: "chhattisgarh",
    rj: "rajasthan",
    gj: "gujarat",
    mt: "maharashtra",
  };

  const options = [
    {
      value: 0,
      label: "2019",
    },
    // {
    //   value: 2,
    //   label: '2024'
    // }
  ];
  const colorOptions = {
    llt: "#eb0e8c",
    tg: "#d9368b",
    mz: "#8ab3dc",
    mp: "#0c8a70",
    cg: "#2d8468",
    llt: "#d9368b",
    rj: "#fed029",
    up: "#f6523e",
    gj: "#f362b3",
    ct: "#B71F33",
    mt: "#f92929",
  };

  async function getWinnersList() {
    await fetch(
      `https://akm-img-a-in.tosshub.com/sites/elections/2024/loksabha/json/master/india-seat-wise-livedata-master.json`
    ).then(async (data) => {
      const data1 = await data.json();
      console.log("All data ",data1?.data)
      const regionalData = data1?.data.filter((con)=>
        con?.state_id === stateId[regional]
      )
      setWinnersData(regionalData);
    });
  }
  useEffect(() => {
    getWinnersList();
  }, []);
  useEffect(() => {
    if (winnersData?.length > 0) {
      setPrevData(winnersData);
    }
  }, [winnersData]);
  function findSearchData(value) {
    const query = value.toLowerCase();
    const prevData = winnersData;
    const newData = prevData.filter(
      (item) =>
        item.cons_name.toLowerCase().includes(query) ||
        item.winner.toLowerCase().includes(query)
    );
    setPrevData(newData);
  }
  console.log("data ", winnersData);
  return (
    <>
      <div className="result-page">
        {/* <div>
        <h1>
          Lok Sabha Election 2019 Constituency Wise Winning Candidates List
        </h1>
      </div> */}
        <div className="tp-data">
          {/* <div className="tp-select">
          <ul>
            <li className="lead">
              Lead
              <img
                alt="lead"
                src="https://akm-img-a-in.tosshub.com/sites/electiontak/images/app-up-arrow.png"
              />
            </li>
            <li className="won">
              Won
              <img
                alt="won"
                height="16px"
                src="https://akm-img-a-in.tosshub.com/sites/electiontak/images/won.png"
              />
            </li>
          </ul>
        </div> */}
        </div>
        <div className="winners-list-box">
          <h2 style={{ fontSize: "22px", fontWeight: "700" }}>
            निर्वाचन क्षेत्र और उम्मीदवार
          </h2>
          {/* <br></br> */}
          {/* <div style={{ position: "relative" }}>
            <p className="filterby" style={{ position: "absolute" }}>
              Filter By
            </p>
            <Select
              options={options}
              style={{
                paddingLeft: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              values={[{ value: 1, label: "2019" }]}
              // onChange={(values) => this.setValues(values)}
            />
          </div> */}
          <div>
            <br></br>
            <div class="search_cont snipcss0-0-0-1 snipcss-rNdJP">
              {" "}
              <input
                type="text"
                id="winner_list"
                placeholder="Search for names.."
                title="Type in a name"
                autocomplete="off"
                class="snipcss0-1-1-2"
                onChange={(e) => {
                  findSearchData(e.target.value);
                }}
              />{" "}
            </div>
          </div>
          {/* <br></br> */}
          <div>
            <table>
              <th style={{ backgroundColor: colorOptions[regional] }}>
                निर्वाचन क्षेत्र
              </th>
              <th style={{ backgroundColor: colorOptions[regional] }}>
                पार्टी
              </th>
              <th style={{ backgroundColor: colorOptions[regional] }}>
                विजेता
              </th>
              {prevData.map((con, index) => {
                return (
                  <tr
                    className={index % 2 !== 0 ? "oddColor" : ""}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() =>
                      window.open(
                        `${`${baseUrl || redirectUrl[regional]}${
                          subUrl || regional === "mt" ? `/election/lok-sabha-2024-election` : `/elections/lok-sabha-elections-2024`
                        }/${con?.cons_name
                          .toLowerCase()
                          .replace(/[^a-zA-Z ]/g, "")
                          .split(" ")
                          .join("-")}-constituency-result-${
                          con?.seat_number
                        }`}`,
                        "_blank"
                      )
                    }
                  >
                    <td style={{ textAlign: "center" }}>{con?.cons_name_h}</td>
                    <td style={{ textAlign: "center" }}>{con?.winner_party}</td>
                    <td className="winner-name" style={{ textAlign: "center" }}>
                      { con?.winner.replace(/&apos;/g, "")}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegionalsElectionResultsComponent;
