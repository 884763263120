import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
// import Grid from "@mui/material/Grid";
import "./index.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Fab from "@mui/material/Fab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useIsMobile from "../../../useIsMobile";
// import { Typography } from "@mui/material";
// import RectangleIcon from '@mui/icons-material/Rectangle';

const partyColor = {
  NDA: "#F57E20",
  BJP: "#F57E20",
  "BJP+": "#F57E20",
  BSP: "#0001FF",
  SP: "#CF2323",
  "SP+": "#CF2323",
  CONG: "#4B91CF",
  INDIA: "#4B91CF",
  "CONG+": "#4B91CF",
  OTH: "#575757",
  AAP: "#074200",
  "SAD+": "#F4C300",
  "TMC+": "#004169",
  TMC: "#004169",
  "JD(S)": "#019e3a",
  TMP: "#0474cc",
  "LEFT+": "#dc1c2c",
  NPP: "#f0ac04",
  "NDPP+": "#f42428",
  NPF: "#b4bcec",
};

const partyLogo = {
  BJP: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
  "BJP+": "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
  BSP: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bsp.jpg",
  SP: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/sp.jpg",
  "SP+": "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/sp.jpg",
  CONG: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/inc.jpg",
  "CONG+": "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/inc.jpg",
  OTH: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/others.jpg",
  AAP: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/aap.jpg",
  "SAD+": "#F4C300",
  "TMC+": "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/aitc.jpg",
  TMC: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/aitc.jpg",
  "JD(S)": "#019e3a",
  TMP: "#0474cc",
  "LEFT+": "#dc1c2c",
  NPP: "#f0ac04",
  "NDPP+": "#f42428",
  NPF: "#b4bcec",
  INDIA : "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/default-partylogo.jpg",
  NDA: "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
};

const NameColors = [
  "#96288B",
  "#409031",
  "#00A5E3",
  "#BE1C2F",
  "#EB0304",
  "#004877",
  "#B42730",
  "#FFA07A",
  "#FFCC80",
  "#FFB6C1",
];

function ExitPollRegionalComponentV2() {
  const [stateData, setStateData] = useState();
  // const { state } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();

  // const tempData = {};

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const theme = params.get("theme");
  const stateId = params.get("site");
  // const amp = params.get("amp");
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollableWidth, setScrollableWidth] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const isMobile = useIsMobile();

  

  // Ref to the scrollable div
  const scrollableDivRef = useRef(null);
  const scrollableFixedRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  // Function to handle scrolling the div
  const scrollDiv = (amount) => {
    const newScrollPosition = scrollPosition + amount;
    // console.log('amount' , amount , 'the new pos' , newScrollPosition , 'old position' , scrollPosition)
    // Update the scroll position state
    setScrollPosition(newScrollPosition);
    // console.log('the scroll position is' , scrollPosition)

    // Scroll the div using scrollTo() method
    scrollableDivRef.current.scrollTo({
      left: newScrollPosition,
      behavior: "smooth", // Optional: smooth scrolling effect
    });
  };
  useEffect(() => {
    // Calculate the width of the scrollable content once the component is mounted
    if (scrollableDivRef?.current?.scrollWidth) {
      const contentWidth = scrollableDivRef.current.scrollWidth;
      const limit = contentWidth>500?contentWidth-500:contentWidth+500
      // console.log("contentWidth" , contentWidth)
      // console.log("scrollPosition" , scrollableFixedRef.current.offsetWidth , scrollableDivRef.current.offsetWidth)
      // console.log("scrollableFixedRef.current.offsetWidth" , scrollableFixedRef.current.offsetWidth)
      // console.log("limit" , contentWidth)
      // Set the total width of the scrollable content in state
      let temp = parseInt(scrollableDivRef.current.offsetWidth)
      setScrollableWidth(temp);
      setCanScrollRight(scrollPosition < limit);
      setCanScrollLeft(scrollPosition > 0);
    }
  }, [scrollableDivRef, scrollPosition, scrollableFixedRef]);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    // console.log('start' , touchStartX.current , 'end' , touchEndX.current , 'width' , scrollableWidth , 'scrollPosition' , scrollPosition)
    if (touchStartX.current - touchEndX.current > 50) {
      if(canScrollRight){scrollDiv(scrollableWidth)};
    }

    if (touchStartX.current - touchEndX.current < -50) {
      if(canScrollLeft){ 
        // console.log('scrollableWidth' , scrollableWidth) 
        scrollDiv(-scrollableWidth)};
    }
  };

  useEffect(() => {
    const slides = scrollableDivRef.current;
    slides.addEventListener('touchstart', handleTouchStart);
    slides.addEventListener('touchmove', handleTouchMove);
    slides.addEventListener('touchend', handleTouchEnd);

    return () => {
      slides.removeEventListener('touchstart', handleTouchStart);
      slides.removeEventListener('touchmove', handleTouchMove);
      slides.removeEventListener('touchend', handleTouchEnd);
    };
  }, [scrollableWidth , scrollPosition , canScrollLeft , canScrollRight]);
  // console.log("theme  is ", stateId);

  // Check if there's more content to scroll to the left
  // const canScrollLeft = scrollPosition > 0;

  // Check if there's more content to scroll to the right
  // const canScrollRight = scrollPosition + scrollableDivRef.current.offsetWidth < scrollableWidth;

  const fetchPartiesData = async () => {
    const resData = await axios.get(
      `https://elections.mobiletak.com/jsonfiles/exitpolls/statesPollData.json`
    );
    const partyArr = resData.data;
    let tempStateData= await partyArr.filter((item)=>{if(item.agency==stateId){return item}})
      let resultAwaited = true;
      for(let each of tempStateData[0].pollData){
        // console.log('the each is' , each)
        for(let item of each.partyData){
          // console.log('the item is' , item , tempStateData[0].totalSeats)
          // console.log('the percent' , ((item.midRange*100)/tempStateData[0].totalSeats))
          if(item.midRange!=0){
            resultAwaited = false;
          }
          item.percentage = parseInt((item.midRange*100)/tempStateData[0].totalSeats);
        }
        each.resultAwaited = resultAwaited;
      }
      // console.log("partyArr", tempStateData);
    setStateData(tempStateData[0])
    // setStateData(partyArr);
  };

  useEffect(() => {
    fetchPartiesData();

    // Set up the interval to call the function every 20 seconds
    const interval = setInterval(fetchPartiesData, 20000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="MainContainer" ref={scrollableFixedRef}>
      <div className="LeftDiv">
        <Fab
          // color="secondary"
          aria-label="add"
          disabled={!canScrollLeft}
          style={{ height: "4px", width: "36px" , backgroundColor:canScrollLeft?"#828282":"#E2E2E2" , color:"#FFF"}}
          onClick={() => scrollDiv(-scrollableWidth)}
        >
          <ChevronLeftIcon />
        </Fab>
      </div>
      <div ref={scrollableDivRef} className="CenterDiv">
      {stateData?.pollData.map((item, index) => {
        return (
          <div className="AgencyDiv" key={index} style={{position:'relative'}}>          
            <div className="AgencyNameDiv">
            
              <div
                style={{
                  padding: "6px",
                  backgroundColor: NameColors[index],
                  textAlign: "center",
                  borderRadius: "4px",
                  width: "95%",
                }}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontSize: isMobile ? "1rem" : "1.25rem",
                    fontFamily: ["Rubik", "sans-serif"],
                  }}
                >
                  {item?.agencyName}
                </span>
              </div>
            </div>
            {item?.partyData.map((child, index) => {
              return (
                <div key={index} style={{display:'grid'}}>
                  <div className="PartyDataDiv" >
                    <div className="PartyNameDiv">
                      <img
                        src={`${partyLogo[child.partyName]}`}
                        alt="party Symbol"
                        style={{ width: "35px", height: "40px" }}
                      ></img>
                      <span style={{paddingLeft:'4px' , fontWeight:'700'}}>{child.partyName}</span>
                    </div>
                    <div className="PartySeatDiv">
                    <span style={{paddingLeft:'4px' , fontWeight:'700'}}>{child.seats}</span>
                    </div>
                  </div>
                  <div className="SeatSlider">
                    <div className="WinnigSeat" style={{backgroundColor: `${partyColor[child.partyName]}` , borderRadius: "8px" , display: "flex" , width: `${child.percentage}%`,  height: '14px' , justifySelf: 'center' }}></div>
                  </div>
                </div>
              );
            })}
            {item.resultAwaited && <div style={{display:'flex' , justifyContent:'center' , alignItems:'center' , position: 'absolute' , width: '100%' , height:'100%' , backgroundColor:'rgba(255, 255, 255, 0.7)'}}><img src="https://akm-img-a-in.tosshub.com/sites/elections/static/images/exit-poll/india-loader.gif" alt="result awaited" style={{width:'50%' , height:'50%'}}></img></div>}
          </div>
        );
      })}
      </div>
      <div className="RightDiv">
        <Fab
          style={{ height: "4px", width: "36px" , backgroundColor:canScrollRight?"#828282":"#E2E2E2" , color:"#FFF"}}
          // color="secondary"
          aria-label="add"
          disabled={!canScrollRight}
          onClick={() => scrollDiv(scrollableWidth)}
        >
          <ChevronRightIcon />
        </Fab>
      </div>
    </div>
  );
}
export default ExitPollRegionalComponentV2;
