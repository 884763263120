import React from "react";
import "./PartyComponent.css";
import { Card, Container } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";

function PartyComponent({ data, color, url }) {
  const backStyle = {
    background: color,
  };
  // console.log("party: ", data);
  return (
    <a
      className="mainDiv"
      href={url}
      // style={{ height: "130px", minWidth: "150px", marginLeft: 10 }}
    >
      {/* <div className="card">hello</div> */}
      <div className="text-center card">
        <div className="card-header-party" style={backStyle}>
          {data.partyName}
        </div>
        {/* <Container className="card-body-party"> */}
        {/* <Container> */}
        <div className="card-body-party">
          <div className="firstRow">
            <h2 className="card-seats-party-no">{data.seatShare}</h2>
            <h2 className="card-gainloss-party-no">{data.seatGainLoss}</h2>
          </div>
          {/* <div className="verticalLine">
            <div className="vertical"></div>
          </div> */}
          <div className="secondRow">
            <h4 className="card-seats-party">seats</h4>
            <h4 className="card-gainloss-party">gain/loss</h4>
          </div>
        </div>
        {/* </Container> */}
        {/* </Container> */}
      </div>
    </a>
  );
}

export default PartyComponent;
