import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./index.css";

import "../RegionalComponent/RegionalComponent.css";

const partyColor = {
  BJP: "#F57E20",
  "BJP+": "#F57E20",
  BSP: "#0001FF",
  SP: "#CF2323",
  "SP+": "#CF2323",
  CONG: "#4B91CF",
  "CONG+": "#4B91CF",
  OTH: "#575757",
  AAP: "#074200",
  "SAD+": "#F4C300",
  "TMC+": "#004169",
  TMC: "#004169",
  "JD(S)": "#019e3a",
  TMP: "#0474cc",
  "LEFT+": "#dc1c2c",
  NPP: "#f0ac04",
  "NDPP+": "#f42428",
  NPF: "#b4bcec",
};

const websiteUrl = {
  mp: "https://m.mptak.in/article/politics/assembly-election-2023-result-live-updates/81602",
  cg: "https://m.chhattisgarhtak.in/article/politics/elections-result-live-updates/8695",
  rj: "https://m.rajasthantak.com/article/politics/assembly-election-2023-result-live-updates/100959",
  tg: "https://www.newstak.in/politics/assembly-election-2023-result-live-updates?source=tg",
  mz: "https://www.newstak.in/politics/assembly-election-2023-result-live-updates?source=mz",
};

const partyBoxColor = {
  INC: "#4b91cf1a",
  CONG: "rgb(75 145 207 / 28%)",
  "BJP+": "#f57e201a",
  BJP: "rgb(245 126 32 / 26%)",
  OTH: "rgb(157 157 157 / 30%)",
  BSP: "#6060d81a",
  "SP+": "#cf23231a",
};

const stateSlug = {
  mp: "Madhya Pradesh",
  rj: "Rajasthan",
  cg: "Chhattisgarh",
  tg: "Telangana",
  mz: "Mizoram",
};

const stateSlugHindi = {
  mp: "मध्य प्रदेश",
  rj: "राजस्थान",
  cg: "छत्तीसगढ़",
  tg: "तेलंगाना",
  mz: "मिजोरम",
};

const themeColor = {
  lallantop: "#d9368b",
  mz: "#8ab3dc",
  mp: "#0c8a70",
  cg: "#2d8468",
  tg: "#ee403c",
  rj: "#fed029",
};

const stateUrl = {
  "Uttar Pradesh":
    "https://www.indiatoday.in/election-analysis-dashboard/uttar-pradesh/2022/",
  Punjab: "https://www.indiatoday.in/election-analysis-dashboard/punjab/2022/",
  Uttarakhand:
    "https://www.indiatoday.in/election-analysis-dashboard/uttarakhand/2022/",
  GOA: "https://www.indiatoday.in/election-analysis-dashboard/goa/2022/",
  Manipur:
    "https://www.indiatoday.in/election-analysis-dashboard/manipur/2022/",
};

function ExitPollRegionalComponent() {
  const [stateData, setStateData] = useState([]);
  const { state } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const theme = params.get("theme");
  const amp = params.get("amp");
  const navigate = useNavigate();
  console.log("theme  is ", state);

  const fetchPartiesData = async () => {
    const resData = await axios.get(
      `https://edr-broadcaster.mobiletak.com/exitPoll/${state}`
    );
    const partyArr = resData.data.data;
    console.log("partyArr", partyArr);
    setStateData(partyArr);
  };

  useEffect(() => {
    fetchPartiesData();
  }, []);

  // useEffect(() => {
  //   const sse = new EventSource("https://edr-broadcaster.mobiletak.com/sse");
  //   // const sse = new EventSource("http://localhost:3050/sse");
  //   function getRealtimeData(dataStream) {
  //     if (dataStream?.data && dataStream?.data?.length > 0) {
  //       setStateData(dataStream?.data);
  //     }
  //   }
  //   sse.onmessage = (res) => getRealtimeData(JSON.parse(res.data));
  //   sse.onerror = (e) => {
  //     console.log(e);
  //     sse.close();
  //   };
  //   return () => {
  //     sse.close();
  //   };
  // }, []);

  // console.log("CurrentStat ", stateData, currentState, state);

  return (
    <table className="my-table">
      <thead>
        <tr>
          <th className="table-header">Agency</th>
          <th className="table-header">BJP</th>
          <th className="table-header">CONG</th>
          <th className="table-header">SP</th>
          {/* <th className="table-header">OTH</th> */}
        </tr>
      </thead>
      <tbody>
        {stateData.map((row) => (
          <tr key={row.Agency}>
            <td className="table-data">{row.Agency}</td>
            {console.log("row", row)}
            {row?.partyData?.map((party) => (
              <td className="table-data">{party.seats}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
export default ExitPollRegionalComponent;
