import React from "react";
import "./PartyComponentLallantop.css";
import { Card, Container } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import "@fontsource/mukta"; 

function PartyComponent({ stateName, data, color, url }) {
  const backStyle = {
    background: color,
  };
  // console.log("party: ", data);
  return (
    <a
      className={
        stateName === 'Gujarat'? "partyCard-Gujrat" : 
        stateName === 'Himachal Pradesh' ? "partyCard-HP" :
        "partyCard"
      }
      style={backStyle}  
      href={url}
      // style={{ height: "130px", minWidth: "150px", marginLeft: 10 }}
    >
      <div>
        <div className="partyTitle">
            <p style={{marginBlockEnd: "0", marginBlockStart: "0"}}>{data.partyName}</p>
        </div>
        <div className="partyScore">
            <p style={{marginBlockEnd: "0", marginBlockStart: "0"}}>{data.seatShare}</p>
        </div>
      </div>
    </a>
  );
}

export default PartyComponent;
