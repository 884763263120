import React, { useEffect, useState } from "react";
import "./index.css";
import Select from "react-dropdown-select";
import { useSearchParams } from "react-router-dom";

const ElectionResultsComponent = () => {
  const [winnersData, setWinnersData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [searchParams] = useSearchParams();
  const website = searchParams.get("website");
  const options = [
    {
      value: 0,
      label: "2019",
    },
    // {
    //   value: 2,
    //   label: '2024'
    // }
  ];
  const colorOptions = {
    llt: "#eb0e8c",
    tg: "#d9368b",
    mz: "#8ab3dc",
    mp: "#0c8a70",
    cg: "#2d8468",
    llt: "#d9368b",
    rj: "#fed029",
    up: "#f6523e",
    gj: "#f362b3",
    ct: "#B71F33",
    mt: "#f92929",
  };
  async function getWinnersList() {
    await fetch(
      "https://akm-img-a-in.tosshub.com/sites/elections/2024/loksabha/json/master/india-seat-wise-livedata-master.json"
    ).then(async (data) => {
      const data1 = await data.json();
      setWinnersData(data1?.data);
    });
  }
  useEffect(() => {
    getWinnersList();
  }, []);
  useEffect(() => {
    if (winnersData?.length > 0) {
      setPrevData(winnersData);
    }
  }, [winnersData]);
  function findSearchData(value) {
    const query = value.toLowerCase();
    const prevData = winnersData;
    const newData = prevData.filter(
      (item) =>
        item.cons_name.toLowerCase().includes(query) ||
        item.winner.toLowerCase().includes(query)
    );
    setPrevData(newData);
  }
  console.log("data ",winnersData)
  return (
    <>
      <div className="result-page">
        {/* <div>
        <h1>
          Lok Sabha Election 2019 Constituency Wise Winning Candidates List
        </h1>
      </div> */}
        <div className="tp-data">
          {/* <div className="tp-select">
          <ul>
            <li className="lead">
              Lead
              <img
                alt="lead"
                src="https://akm-img-a-in.tosshub.com/sites/electiontak/images/app-up-arrow.png"
              />
            </li>
            <li className="won">
              Won
              <img
                alt="won"
                height="16px"
                src="https://akm-img-a-in.tosshub.com/sites/electiontak/images/won.png"
              />
            </li>
          </ul>
        </div> */}
        </div>
        <div className="winners-list-box">
          <h2 style={{ fontSize: "22px", fontWeight: "700" }}>
            निर्वाचन क्षेत्र और उम्मीदवार
          </h2>
          {/* <div style={{ position: "relative" }}>
            <p className="filterby" style={{ position: "absolute" }}>
              Filter By
            </p>
            <Select
              options={options}
              style={{paddingLeft:"20px",paddingTop:"10px",paddingBottom:"10px"}}
              values={[{ value: 1, label: "2019" }]}
              // onChange={(values) => this.setValues(values)}
            />
          </div> */}
          <div>
            <br></br>
            <div class="search_cont snipcss0-0-0-1 snipcss-rNdJP">
              {" "}
              <input
                type="text"
                id="winner_list"
                placeholder="Search for names.."
                title="Type in a name"
                autocomplete="off"
                class="snipcss0-1-1-2"
                onChange={(e) => {
                  findSearchData(e.target.value);
                }}
              />{" "}
            </div>
          </div>
          {/* <br></br> */}
          <div>
            <table>
              <th style={{ backgroundColor: colorOptions[website] }}>
                निर्वाचन क्षेत्र
              </th>
              <th style={{ backgroundColor: colorOptions[website] }}>पार्टी</th>
              <th style={{ backgroundColor: colorOptions[website] }}>विजेता</th>
              {prevData.map((con, index) => {
                return (
                  <tr className={index % 2 !== 0 ? "oddColor" : ""} style={{cursor:"pointer",textDecoration:"underline",letterSpacing:"normal"}} 
                  onClick={()=>
                    window.open(`${website === null ? `/elections/constituency-list-2019/${con?.const_id}` : website === "llt" ? `https://thelallantop.com/all/election/lok-sabha-chunav-2024/results/${con?.state_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").split(" ").join("-")}/${con?.cons_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").split(" ").join("-")}-constituency-result/${con?.const_id}` : `https://www.newstak.in/elections/lok-sabha-election-2024/${con?.state_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").split(" ").join("-")}/${con?.cons_name.toLowerCase().replace(/[^a-zA-Z ]/g, "").split(" ").join("-")}-constituency-result-${con?.const_id}`}`,'_blank')
                  } >
                    <td style={{ textAlign: "center" }}>{con?.cons_name_h}</td>
                    <td style={{ textAlign: "center" }}>{con?.winner_party}</td>
                    <td className="winner-name" style={{ textAlign: "center" }}>
                      {con?.winner}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElectionResultsComponent;
