import React from "react";
import "./StateComponent.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const themeColor = {
  tg:"#d9368b",
  mz:"#8ab3dc",
  mp:"#0c8a70",
  cg:"#2d8468",
  llt:"#d9368b",
  rj:"#fed029",
  up: "#f6523e",
  gj:"#f362b3",
  ct: "#B71F33",
  mt:"#f92929"
}
const partyColor = {
  BJP: "#F57E20",
  "BJP+": "#F57E20",
  BSP: "#0001FF",
  SP: "#CF2323",
  "SP+": "#CF2323",
  CONG: "#4B91CF",
  "CONG+": "#4B91CF",
  OTH: "#575757",
  AAP: "#074200",
  "SAD+": "#F4C300",
  "TMC+": "#004169",
  TMC: "#004169",
  "JD(S)": "#019e3a",
  TMP: "#0474cc",
  "LEFT+": "#dc1c2c",
  NPP: "#f0ac04",
  "NDPP+": "#f42428",
  NPF: "#b4bcec",
};

const partyBoxColor = {
  INC: "#4b91cf1a",
  CONG: "rgb(75 145 207 / 28%)",
  "BJP+": "#f57e201a",
  BJP: "rgb(245 126 32 / 26%)",
  OTH: "rgb(157 157 157 / 30%)",
  BSP: "#6060d81a",
  "SP+": "#cf23231a",
};
function StateComponent({ stateName, totalSeats, seats, url }) {
  // console.log("state: ", url);
  const [isOpen, setIsOpen] = useState(false);
  const [openedState,setOpenedState] = useState("BHOPAL");
  const [data,setData] = useState([]);
  const [data2,setData2] = useState([]);
  const [allData,setAllData] = useState("");
  const [partyData,setPartyData] = useState([])
  const navigation = useNavigate();
  const [district,setDistrict] = useState([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const theme = params.get('theme');
  const {state} = useParams();
  const toggleDropdown = ({state}) => {
    setIsOpen(!isOpen);
    setOpenedState(state);
  };
  const stateSlug = {
    "mp":"Madhya Pradesh",
    rj:"Rajasthan",
    cg:"Chhattisgarh",
    tg:"Telangana",
    mz:"Mizoram"
  }
  const fetchPartiesData = async () => {
    const resData = await axios.get(
      `https://edr-broadcaster.mobiletak.com/districtData/${state}`
    );
    const partyArr = resData.data.data;
    setData(partyArr.districtData);
    setAllData(partyArr)
  };

  const fetchPartiesData2 = async () => {
    const resData = await axios.get(
      "https://edr-broadcaster.mobiletak.com/partiesData"
    );
    const partyArr = resData.data.data;
    let temp = partyArr.filter((x)=>x.shortName.toLowerCase() === state)
    setPartyData(temp);
  };

  useEffect(() => {
    const sse = new EventSource("https://edr-broadcaster.mobiletak.com/sse");
    // const sse = new EventSource("http://localhost:3050/sse");
    function getRealtimeData(dataStream) {
      if (dataStream?.data && dataStream?.data?.length > 0) {
        let temp = dataStream?.data.filter((x)=>x.shortName.toLowerCase() === state)
        setPartyData(temp);
      }
    }
    sse.onmessage = (res) => getRealtimeData(JSON.parse(res.data));
    sse.onerror = (e) => {
      console.log(e);
      sse.close();
    };
    return () => {
      sse.close();
    };
  }, [allData]);

  useEffect(() => {
    fetchPartiesData();
    fetchPartiesData2();
    // const interval = setInterval(() => fetchPartiesData(), 6050);
    // return () => {
    //   clearInterval(interval);
    // };
  }, []);
  useEffect(()=>{
    const temp = data.filter((x)=>x.state == stateSlug[state]);
   setDistrict(temp[0]);
   setData2(data)
  },[data]);
  function isFound(cons,key){
    let y = cons.filter((temp)=>temp.constituencyName.toLowerCase().indexOf(key.toLowerCase()) >= 0);
    if(y.length > 0){
      return true;
    }
    return false;
  }
  // function handleSearch(key){
  //   if(key === ""){
  //     setData2(data)
  //     return;
  //   }
  //   let x = [];
  // //  let x = data.filter((temp)=>temp.district.toLowerCase().indexOf(key.toLowerCase()) >= 0);
  //   for(let i=0;i<data.length;i++){
  //     if(data[i].district.toLowerCase().indexOf(key.toLowerCase()) >= 0 || isFound(data[i].constituencies,key)){
  //       x.push(data[i]);
  //       break;
  //     }
  //   }
  //  setData2(x);
  // }

  function handleSearch(key){
    const resultArray = [];

  // Iterate over the data
  for (const item of data) {
    // Check if the district matches the key
    if (item.district.toLowerCase().indexOf(key.toLowerCase()) >=0) {
      resultArray.push(item);
    } else {
      // Check if the key exists in any consName within the constituency array
      const consNameMatch = item.constituencies.some(
        (cons) => cons.constituencyName.toLowerCase().indexOf(key.toLowerCase()) >= 0
      );

      if (consNameMatch) {
        resultArray.push(item);
      }
    }
  }
  setData2(resultArray)
  }
  console.log(data2)
  return (
    // <>
    //   <a
    //     className="mainDivState"
    //     href={url}
    //   // style={{ height: "130px", minWidth: "160px" }}
    //   >
    //     {/* <div className="card">hello</div> */}
    //     <div className="text-center card">
    //       <div className="card-header-state">{stateName}</div>
    //       <div className="card-body-state">
    //         <div className="firstRowState">
    //           <h3 className="card-title-state">Total Seats: {totalSeats}</h3>
    //         </div>
    //         <div className="secondRowState">
    //           <h3 className="card-text-state">Majority: {parseInt(totalSeats / 2) + 1}</h3>
    //         </div>
    //       </div>
    //     </div>
    //   </a>

    //   <a
    //     className="mainDivStateMob text-center"
    //     // style={{ height: "130px", minWidth: "160px" }}
    //     href={url}
    //   >
    //     <div className="card-header-state-mob">{stateName}</div>
    //     <h3 className="card-title-state-mob">Total Seats: {totalSeats}</h3>
    //     <h3 className="card-text-state-mob">Majority: {parseInt(totalSeats / 2) + 1}</h3>
    //   </a>
    // </>
    <div>
      <div className="outer-div-state">
        {/* <div
          style={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px",textAlign:"center" }}
          onClick={()=>navigation("/")}
        >
          <img className="main-image" src="/frame-mobile.png" width="300px"></img>
        </div> */}
         <div className="election-title-header" style={{display:"flex", marginLeft:"10px",gap:"10px",marginTop:"1rem"}}>
          <div className="election-title"></div>
          <span className="election-title-span">Election Results</span>
        </div>
        <div className="conventions"> {((state === "mz" || state === "tg") && theme !== "llt")  ? "Won" : "जीते"} <img src="/like.svg"></img>{((state === "mz" || state === "tg") && theme !== "llt")  ? " Leading" : "आगे"}  <img src="/arrow-up.svg"></img>  </div>
        <div className="state-name-box" style={{background:themeColor[theme]}}><div>{ ((allData?.state === "Mizoram" || allData?.state === "Telangana") && theme !== "llt") ? allData?.state : allData?.stateNameHindi} ({allData?.seatCount})</div></div>
        <div className="mobile-table">
          <div className="mobile-table-row">
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              PARTY
            </span>
            <span style={{ alignSelf: "center" }}>SEATS {partyData[0]?.totalSeats}</span>
          </div>
          {partyData[0]?.partiesData?.map((party)=>{
            return  <div
            className="mobile-table-row"
            style={{ background: partyBoxColor[party.partyName] ?  partyBoxColor[party.partyName] : partyBoxColor["CONG"] }}

          >
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <div
                className="party-color-small"
                style={{ background: partyColor[party?.partyName] }}
              ></div>
              {party?.partyName}
            </span>
            <span style={{ alignSelf: "center" }}>{party?.seatShare}</span>
          </div>
          })}
        </div>
        <div style={{padding:"5px",display:"flex",marginTop:"10px",marginBottom:"10px"}}>
          <img src="/search3.png" width="25px" height="23px" style={{background:"#8f8c8c",alignSelf:"center",marginRight:"5px",padding:"4px"}}></img>
          <input type="text" onChange={(e)=>handleSearch(e.target.value)} style={{width:"95vw",height:"25px"}} placeholder="Search"  ></input>
        </div>
        <div className="table-scrollable">
        {data2?.map((dist,index)=>{
          return  <div key={index} className="dropdown-container">
          <button /**onClick={()=>toggleDropdown({state:dist?.district})}**/ style={{background:themeColor[theme]}} className="state-button">
            <div>
              { ((state === "mz" || state === "tg") && theme !== "llt") ? dist?.district : dist?.districtHI}
            </div>
            {/* <div className="state-score"> 20, 12 </div> */}
            {/* <div>{isOpen && openedState === dist?.district ? "-" : "+"}</div> */}
            </button>
          {(
            <div className={`dropdown-content ${isOpen && openedState === dist?.district ? "visible": "visible"} `}>
              {dist?.constituencies.map((cons)=>{
               return  <div className="state-button-option">
                <div>{((state === "mz" || state === "tg") && theme !== "llt") ? cons?.constituencyName:  cons?.constituencyNameHI}</div>
                <div>{cons.party === "INC" ? "CONG" :cons.party} 
                {cons.partyStatus &&<img src={cons?.partyStatus === "WON" ? "/like.svg": "/arrow-up.svg"} style={{marginLeft:"5px"}}></img>}
                </div>
                </div>
              })} 
            </div>
          )}
        </div>
        })}
        </div>
       
      </div>
    </div>
  );
}

export default StateComponent;
