import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { useParams, useSearchParams } from "react-router-dom";

import "./index.css";
// import { Dropdown, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropdown/style.css";

const ResponsiveCard = ({
  constituencyListData = null,
  selectedConstituencyId = "",
  colorCode = "#000",
  constId = null,
}) => {
  console.log("current Obj ", constId);
  const redirectUrl = {
    up: "https://www.uptak.in",
    mp: "https://www.mptak.in",
    ch: "https://www.chhattisgarhtak.in",
    rj: "https://www.rajasthantak.com",
    gj: "https://www.gujarattak.in",
    mt: "https://mumbaitak.in",
  };
  const [readMore, setReadMore] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [constList, setConstList] = useState([]);
  const [currentState, setCurrentState] = useState({});
  const [currentConst, setCurrentConst] = useState({});
  const [searchParams] = useSearchParams();
  const stateID = searchParams.get("state-id");
  const [isChanged, setIsChanged] = useState(false);
  // const website = searchParams.get("website");
  const { regional } = useParams();
  const website = regional;
  const handleReadMoreClick = () => {
    setReadMore(!readMore);
  };
  console.log("stateID", website);
  const [dropDownData, setDropdownData] = useState([]);

  useEffect(() => {
    if (constituencyListData) {
      const nameMap = constituencyListData.reduce((acc, curr) => {
        acc[curr.state_name_hi] = curr.state_id;
        return acc;
      }, {});

      let newArray = Object.keys(nameMap).map((fname) => {
        return {
          label: fname,
          value: nameMap[fname],
        };
      });
      if (stateID) {
        newArray = newArray?.filter((item) => item.value == stateID);
      }
      setStateList(newArray);
    }
    if (constId) {
      let obj = constituencyListData.filter(
        (item) => item?.const_id == constId
      );
      if (obj && obj[0]) {
        setCurrentState({
          label: obj[0]?.state_name,
          value: obj[0]?.state_id,
        });
        setCurrentConst({
          label: obj[0]?.cons_name_h,
          value: obj[0]?.const_id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (currentState) {
      const data = constituencyListData.filter(
        (item) => item?.state_id === currentState?.value
      );
      let tempConst = [];
      data.map((item, index) => {
        tempConst.push({
          label: item?.cons_name_h,
          value: item?.const_id,
        });
      });
      setConstList(tempConst);
      // setIsChanged(true)
    }
  }, [currentState]);

  useEffect(() => {
    if (isChanged) {
      const data = constituencyListData.filter(
        (item) => item?.const_id === currentConst?.value
      );
      console.log("Both are ", data);
      window.open(
        `${
          website === null
            ? `/elections/constituency-list-2019/${data[0]?.cons_id}`
            : website === "llt"
            ? `https://preprod.thelallantop.com/all/election/lok-sabha-chunav-2024/results/${data[0]?.state_name
                .toLowerCase()
                .replace(/[^a-zA-Z ]/g, "")
                .split(" ")
                .join("-")}/${data[0]?.cons_name
                .toLowerCase()
                .replace(/[^a-zA-Z ]/g, "")
                .split(" ")
                .join("-")}/${data[0]?.cons_id}`
            : `${redirectUrl[website]}${
                regional === "mt"
                  ? `/election/lok-sabha-2024-election`
                  : `/elections/lok-sabha-elections-2024`
              }/${data[0]?.cons_name
                .toLowerCase()
                .replace(/[^a-zA-Z ]/g, "")
                .split(" ")
                .join("-")}-constituency-result-${data[0]?.const_id}`
        }`,
        "_blank"
      );
    }
  }, [currentConst]);

  const fullText = `अगर प्रदेश का एक प्रमुख नगर और हिस्टा है वाराणसी बासास और काशी के नाम से भी जाना जाता है. इस हिस्टा में एक पतन नगर माना जाता है. ठंड और उमस के लिए भी एक महत्वपूर्ण शहर है...
  अगर प्रदेश का एक प्रमुख नगर और हिस्टा है वाराणसी बासास और काशी के नाम से भी जाना जाता है. इस हिस्टा में एक पतन नगर माना जाता है. ठंड और उमस के लिए भी एक महत्वपूर्ण शहर है
  अगर प्रदेश का एक प्रमुख नगर और हिस्टा है वाराणसी बासास और काशी के नाम से भी जाना जाता है. इस हिस्टा में एक पतन नगर माना जाता है. ठंड और उमस के लिए भी एक महत्वपूर्ण शहर है`;
  const previewText = `अगर प्रदेश का एक प्रमुख नगर और हिस्टा है वाराणसी...`;
  console.log("after useEffect", currentConst, currentState);
  return (
    <div className="responsive-card shadow-sm">
      {/* <h6
        className="text-muted"
        style={{ fontSize: "12px", paddingBottom: "10px" }}
      >
        वाराणसी
      </h6> */}
      {/* <Dropdown className="w-100 rounded-2">
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="w-100 text-start border border-light border-1 bg-white "
        >
          वाराणसी
        </Dropdown.Toggle>
        <Dropdown.Menu defaultValue={selectedConstituencyId} className="w-100">
          {constituencyListData &&
            constituencyListData.map((item, index) => (
              <Dropdown.Item value={item?.cons_id} key={index} as="button">
                {item?.cons_name_hi}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown> */}
      {/* <h5>Select State</h5>
      <Dropdown
        onChange={(e) => {
          setCurrentState(e);
        }}
        options={stateList}
        value={currentState?.label}
        disabled={stateID ? true : false}
        placeholder="Select an option"
      /> */}
      <br></br>
      <h5>Select Constituency</h5>
      <Dropdown
        onChange={(e) => {
          setCurrentConst(e);
          setIsChanged(true);
        }}
        options={constList}
        value={currentConst?.label}
        disabled={currentState ? false : true}
        placeholder="Select an option"
      />

      {/* <div className="map__image_box">
        <Image src="/city_map.svg" alt="Map" fluid className="mt-3" />
      </div> */}

      {/* <div className="card-text mt-3">
        {readMore ? fullText : previewText}
        <button
          onClick={handleReadMoreClick}
          className="btn btn-link p-0 align-baseline"
          style={{ color: colorCode }}
        >
          {readMore ? "और पढ़ें कम" : "और पढ़ें"}
        </button>
      </div> */}
    </div>
  );
};

const RegionalsConstituencyResultsComponent = () => {
  const [candidatesList2024, setCandidatesList2024] = useState([]);
  const [constituencyData, setConstituencyData] = useState(null);
  const [constituencyListData, setConstituencyListData] = useState(null);
  const [resultYearData, setResultYearData] = useState([]);
  const [currentYear, setCurrentYear] = useState("2019");
  // const [prevData, setPrevData] = useState([]);
  const [searchParams] = useSearchParams();
  const { constituencyId, regional } = useParams();
  const website = searchParams.get("website");
  console.log("CONSTITUENCY DATA ", resultYearData);
  const colorOptions = {
    llt: "#eb0e8c",
    tg: "#d9368b",
    mz: "#8ab3dc",
    mp: "#0c8a70",
    cg: "#2d8468",
    llt: "#d9368b",
    rj: "#fed029",
    up: "#f6523e",
    gj: "#f362b3",
    ct: "#B71F33",
    mt: "#f92929",
  };
  // const options = [
  //   {
  //     value: 0,
  //     label: "2019",
  //   },
  //   // {
  //   //   value: 2,
  //   //   label: '2024'
  //   // }
  // ];
  async function getConstituencyData(constituencyId = "") {
    let url = `https://akm-img-a-in.tosshub.com/sites/elections/2024/loksabha/json/constituency/${constituencyId}.json`;
    await fetch(url).then(async (data) => {
      const data1 = await data.json();
      console.log("data", data1);
      setConstituencyData(data1?.data);
      setResultYearData(data1?.data?.candidate_2019);
    });
  }
  async function getCandidatesList(constituencyId) {
    console.log("Inside find function");
    let url = `https://electionresults.intoday.in/elections/2024/loksabha-elections/json/constituency/${constituencyId}.json`;
    await fetch(url).then(async (data) => {
      const data1 = await data.json();
      console.log("candidatesList", data1?.data?.candidate);
      setCandidatesList2024(data1?.data?.candidate);
    });
  }
  async function getConstituencyListData() {
    let url = `https://akm-img-a-in.tosshub.com/sites/elections/2024/loksabha/json/master/india-seat-wise-livedata-master.json`;
    await fetch(url).then(async (data) => {
      const data1 = await data.json();
      console.log("constituencyListData", data1?.data);
      setConstituencyListData(data1?.data);
    });
  }
  useEffect(() => {
    getConstituencyListData();
    if (constituencyId) {
      getConstituencyData(constituencyId);
      getCandidatesList(constituencyId);
    }
  }, []);
  useEffect(() => {
    if (currentYear === "2019") {
      setResultYearData(constituencyData?.candidate_2019);
    } else if (currentYear === "2014") {
      setResultYearData(constituencyData?.candidate_2014);
    } else if (currentYear === "2024") {
      setResultYearData(constituencyData?.candidate_2024);
    } else {
      setResultYearData([]);
    }
  }, [currentYear]);
  console.log("websit name ", constituencyListData);
  return (
    <>
      <div className="result-page">
        {/* <h2 style={{ fontSize: "22px", fontWeight: "700" }}>
          लोकसभा चुनाव ({constituencyData?.constituency_name})
        </h2> */}
        {constituencyListData && (
          <ResponsiveCard
            constituencyListData={constituencyListData}
            selectedConstituencyId={constituencyId}
            colorCode={colorOptions[regional]}
            constId={constituencyId}
          />
        )}
        {candidatesList2024.length > 0 && (
          <div className="winners-list-box">
            <h3 style={{ letterSpacing: "normal" }}>चुनाव 2024 के उम्मीदवार</h3>
            <table>
              <th
                style={{ backgroundColor: colorOptions[regional], textAlign: "left" }}
              >
                नाम
              </th>
              <th style={{ background: colorOptions[regional] }}>पार्टी</th>
              <th style={{ background: colorOptions[regional] }}>वोट</th>
              {candidatesList2024.map((candidate, index) => {
                return (
                  <tr
                    className={index % 2 !== 0 ? "oddColor" : ""}
                    // onClick={() =>
                    //   (window.location = `/elections/constituency-list-2019/${con?.ac_no}`)
                    // }
                  >
                    <td
                      // style={{
                      //   textAlign: "left",
                      //   paddingLeft:"10%"
                      // }}
                      className="candidate_name"
                    >
                      {candidate?.candidate}
                    </td>
                    <td className="candidate_party">
                     
                      <img
                        width="25px"
                        height="25px"
                        style={{ borderRadius: "100%" }}
                        src={candidate?.party_logo}
                      ></img>
                       {candidate?.party}
                    </td>
                    <td
                      className="winner-name"
                      style={{
                        textAlign: "center",
                        borderLeftColor: "#d2d1d1",
                        borderLeftWidth: "0.2px",
                      }}
                    >
                      {candidate?.win_loss === "Result Awaited" ? (
                        "-"
                      ) : candidate?.win_loss === "WON" ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "auto",
                            width: "auto",
                          }}
                        >
                          <p style={{ margin: "0" }}>38000</p>{" "}
                          <img src="/like.svg" width="15px"></img>
                        </div>
                      ) : candidate?.win_loss === "LOSS" ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "auto",
                            width: "auto",
                          }}
                        >
                          <p style={{ margin: "0" }}>38000</p>{" "}
                        <img src="/dislike.svg" width="15px"></img>
                        </div>
                      ) : candidate?.win_loss === "LEADING" ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "auto",
                            width: "auto",
                          }}
                        >
                          <p style={{ margin: "0" }}>38000</p>{" "}
                        <img src="/arrow-up.svg" width="15px"></img>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "auto",
                            width: "auto",
                          }}
                        >
                          <p style={{ margin: "0" }}>38000</p>{" "}
                        <img src="/arrow-down.svg" width="15px"></img>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        <br></br>
        <div>
          <div
            className="winner-box-header"
            style={{ borderColor: colorOptions[regional] }}
          >
            उम्मीदवार
          </div>
          <br></br>
          <div className="winner-box-years">
            {constituencyData?.candidate_2024 && (
              <button
                className={`${currentYear !== "2024" ? "btn-disable" : ""}`}
                onClick={() => setCurrentYear("2024")}
                style={{ backgroundColor: colorOptions[regional] }}
              >
                2024
              </button>
            )}
            {constituencyData?.candidate_2019 && (
              <button
                className={`${currentYear !== "2019" ? "btn-disable" : ""}`}
                onClick={() => setCurrentYear("2019")}
                style={{ backgroundColor: colorOptions[regional] }}
              >
                2019
              </button>
            )}
            {constituencyData?.candidate_2014 && (
              <button
                className={`${currentYear !== "2014" ? "btn-disable" : ""}`}
                onClick={() => setCurrentYear("2014")}
                style={{ backgroundColor: colorOptions[regional] }}
              >
                2014
              </button>
            )}
          </div>
          <div className="winner-box">
            <div className="winner-box-heading">
              <div style={{ alignSelf: "center" }}>विजेता</div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div style={{ fontSize: "15px" }}>
                  {resultYearData && resultYearData[0]?.candidate}{" "}
                  {resultYearData && resultYearData[0]?.party}
                </div>
                <img
                  width="40px"
                  height="40px"
                  src={resultYearData && resultYearData[0]?.party_logo}
                ></img>
              </div>
            </div>
            <div className="winner-box-heading-2">
              <div style={{ alignSelf: "center" }}>वोट</div>
              <div style={{ fontSize: "15px", alignSelf: "center" }}>
                {resultYearData && resultYearData[0]?.votes}
              </div>
            </div>
            <div className="winner-box-heading-2">
              <div style={{ alignSelf: "center" }}>विजेता पार्टी का वोट %</div>
              <div style={{ fontSize: "15px", alignSelf: "center" }}>
                {resultYearData && resultYearData[0]?.percentage_votes} %
              </div>
            </div>
            <div
              className="winner-box-heading-2"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div style={{ alignSelf: "center" }}>जीत अंतर %</div>
              <div style={{ fontSize: "15px", alignSelf: "center" }}>
                {resultYearData && resultYearData[0]?.winning_percentage} %
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="winners-list-box">
          <div
            className="winner-box-header"
            style={{ borderColor: colorOptions[regional] }}
          >
            अन्य उम्मीदवार
          </div>
          <br></br>
          <div>
            <table class="rounded-3 overflow-hidden">
              <th
                style={{
                  backgroundColor: colorOptions[regional],
                  textAlign: "center",
                }}
              >
                नाम
              </th>
              <th
                style={{
                  backgroundColor: colorOptions[regional],
                  textAlign: "center",
                  borderLeftColor: "white",
                  borderLeftWidth: "1px",
                }}
              >
                पार्टी
              </th>
              <th
                style={{
                  backgroundColor: colorOptions[regional],
                  textAlign: "center",
                  borderLeftColor: "white",
                  borderLeftWidth: "1px",
                }}
              >
                वोट
              </th>
              {constituencyData &&
                resultYearData?.slice(1).map((con, index) => {
                  return (
                    <tr
                      className={index % 2 !== 0 ? "oddColor" : ""}
                      // style={{ cursor: "pointer" }}
                      // onClick={() =>
                      //   (window.location = `/elections/constituency-list-2019/${con?.ac_no}`)
                      // }
                    >
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {con?.candidate}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "black",
                          borderLeftColor: "#d2d1d1",
                          borderLeftWidth: "0.2px",
                        }}
                      >
                        {con?.party}
                      </td>
                      <td
                        className="winner-name"
                        style={{
                          textAlign: "center",
                          borderLeftColor: "#d2d1d1",
                          borderLeftWidth: "0.2px",
                        }}
                      >
                        {con?.votes}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegionalsConstituencyResultsComponent;
