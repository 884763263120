import React from "react";
import "./StateComponentLallantop.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Card } from "react-bootstrap";
import "@fontsource/mukta"; 

function StateComponentLallantop({ stateName, totalSeats, seats, url }) {
  // console.log("state: ", url);
  return (
    <>
      <a
        className={
          stateName === 'Gujarat'? "stateCard-Gujrat" : 
          stateName === 'Himachal Pradesh' ? "stateCard-HP" :
          "stateCard"
        }  
        href={url}
      // style={{ height: "130px", minWidth: "160px" }}
      >
        <div>
            <div className="stateName-2">
                <p style={{marginBlockEnd: "0", marginBlockStart: "0"}}>{stateName === "Himachal Pradesh"? "HP" : stateName}</p>
            </div>
            <div className="stateSeat-2">
                <p style={{marginBlockEnd: "0", marginBlockStart: "0"}}>{`(${seats}/${totalSeats})`}</p>
            </div>
        </div>
      </a>
    </>
  );
}

export default StateComponentLallantop;
