import React from "react";
import ReactDOM from "react-dom";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import ExitPollRegionalComponent from "./App/Pages/ExitPollRegionalComponent";
import MainComponent from "./App/Pages/MainComponent/MainComponent";
import MainComponentLallantop from "./App/Pages/MainComponentLallantop/MainComponentLallantop";
import RegionalComponent from "./App/Pages/RegionalComponent/RegionalComponent";
import StateComponent from "./App/Pages/StateComponent/StateComponent";
import reportWebVitals from "./reportWebVitals";
import ElectionResultsComponent from "./App/Pages/ElectionResultsComponent";
import ConstituencyResultsComponent from "./App/Pages/ConstituencyResultsComponent";
import RegionalsConstituencyResultsComponent from "./App/Pages/RegionalsConstituencyResultsComponent";
import RegionalsElectionResultsComponent from "./App/Pages/RegionalsElectionResultsComponent";

import "./index.css";
import ExitPollRegionalComponentV2 from "./App/Pages/ExitPollRegionalV2";

// import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/tally/exitPoll/:state"
          element={<ExitPollRegionalComponent />}
        />
        <Route path="/tally/all" element={<MainComponent />}></Route>
        <Route path="/" element={<Navigate to="/tally/all" replace />} />
        <Route path="/tally/:state" element={<RegionalComponent />} />
        <Route path="/lallantop" element={<MainComponentLallantop />} />
        <Route path="/tally/table/:state" element={<StateComponent />} />
        <Route
          path="/elections/constituency-list-2019"
          element={<ElectionResultsComponent />}
        />
        <Route
          path="/elections/constituency-list-2019/:constituencyId"
          element={<ConstituencyResultsComponent />}
        />
         <Route
          path="/regionals/elections/constituency-list-2019/:regional"
          element={<RegionalsElectionResultsComponent />}
        />
         <Route
          path="/regionals/elections/constituency-list-2019/:constituencyId/:regional"
          element={<RegionalsConstituencyResultsComponent />}
        />
        <Route
          path="/elections/2024/loksabha-elections/html/exit-poll/stateExit"
          element={<ExitPollRegionalComponentV2 />}
        />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
